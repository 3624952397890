import React, { useState, useEffect  } from 'react';

import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { Spinner } from "react-bootstrap";
//import { withLocale } from "react-easy-localization";
import lodash from 'lodash';


import { BsFillCloudDownloadFill } from "react-icons/bs";
import { FaCashRegister } from "react-icons/fa";



import Page from "../../components/Page";
import NewPagination from "../../components/NewPagination";
import { getUser } from '../../service/auth';
import {LightningPaymentService} from "../../service/LightningPaymentService";
import PaymentsTable from "../../components/PaymentsTable";
import HelpAlert from "../../components/HelpAlert";
import ConfirmModal from "../../components/ConfirmModal";
import { WalletService } from "../../service/WalletService";

import {currencyLang} from "../../env";

import "./style.css";


/**
 * 
 * @param {*} props
	"created_at": "Thu, 26 May 2022 16:35:33 GMT",
	"id": 32,
	"is_unique": 0,
	"k1": "FH2CynM5JthPYgZzpWaQZb",
	"lnurl": "LNURL1DP68GURN8GHJ76MPD3KX7CNN9EHHYEE6X5CRYVP0WA5HG6RYWFSHWTMVDE6HYMP0WD5KUEMVV5HKYJMXG4HKW4PCX49K6NRJD9FRS5N6W34567SSSGUN0",
	"max_withdrawable": 334,
	"min_withdrawable": 334,
	"number": 0,
	"open_time": 1653608133,
	"payment_hash": null,
	"title": "rapaygo POS voucher payment invoice 6a91e 20-EHUJVkFJ ",
	"unique_hash": "bKfEogT85KmLriR8RztkMz",
	"updated_at": "Thu, 26 May 2022 16:35:33 GMT",
	"used": 0,
	"uses": 1,
	"usescsv": "1",
	"wait_time": 0,
	"wallet_id": 20
}} param0 
 * @returns 
 */
const Invoices = ({ useStore }) => {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true); 
  const [satsRate, setSatsRate] = useState(null);
  const [invoices, setInvoices] = useState();
  const [invoicesPage, setInvoicesPage] = useState({page: 1, page_size: 15});
  const [invoicesPageMeta, setInvoicesPageMeta] = useState({total_items_count:15, current_page_number:1, total_pages_count:1});

  const [user] = useState(getUser());
  const [currencyVal, setCurrencyVal] = useState('USD');
  const [currencyLangVal, setCurrencyLangVal] = useState('en-USD');
  const [showModal, setShowModal] = useState(false);
  const [deleteModalError, setDeleteModalError] = useState(false);
  const [deleteHash, setDeleteHash] = useState();
 
  useEffect(() => {
    console.log("useEffect",invoicesPage);
    fetchInvoicesPage (invoicesPage);
  }, [invoicesPage]);

  let fetchInvoicesPage = (invoicesPage) => { 
    console.log("fetchInvoicesPage",invoicesPage);
    setIsLoading(true);
    LightningPaymentService.getInvoicePaymentsPaged(invoicesPage).then((invoices) => {
      console.log("invoices", invoices);
      setInvoices(invoices.data.invoice_payments);
      setInvoicesPage(invoicesPage)
      setInvoicesPageMeta(invoices.data.page_meta);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if(user.settings && user.settings.length > 0) {
      console.log("user.settings", user.settings);
      let currency = user.settings.find(setting => setting.name === "i8n_currency");
      setCurrencyVal(currency.value);
      setCurrencyLangVal(currencyLang[currency.value]);
      console.log("currency", currencyVal, currencyLangVal);
      WalletService.getSatsQuote(currency.value).then((rate) => {
        // console.log("rate", rate.data);
        setSatsRate(rate.data);
      });  
    } else {
      setCurrencyVal('USD');
      setCurrencyLangVal('en-US');
      WalletService.getSatsQuote('USD').then((rate) => {
        // console.log("rate", rate.data);
        setSatsRate(rate.data);
      });
    }

  }, [user]);

  // should trigger reload
  const handlePageChange = (e) => {
    setInvoicesPage({page: 1, page_size: parseInt(e)});
  }

  const handleCSV = () => {
    LightningPaymentService.getInvoicePaymentsCSV().then((csv) => {
      console.log("csv", csv);
      const blob = new Blob([csv.data], {type: 'text/csv;charset=utf-8;'});
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${uuidv4().replace("-","").substring(0,6)}-payments.csv`;
      link.click();
    });
  };

  const showDeleteConfirmation = (payment_hash) => {
    console.log("confirm delete invoice", payment_hash);
    setDeleteHash(payment_hash);
    setShowModal(true);
  };

  const deleteInvoice = () => {
    console.log("deleteInvoice", deleteHash);
    // @TODO needs error handler
    LightningPaymentService.delete(deleteHash).then((response) => {

      //remove the element
      let new_invoices = lodash.filter(invoices, function(o) { 
        return o.payment_hash !== deleteHash; });

      setInvoices(new_invoices);

      // hide the modal
      setShowModal(false); //all done
    }).catch(err => {
      setDeleteModalError("There was a problem while attempting to delete. This is probably because you may accidentally delete information if that is important if you do this.");
    });
  };

  return (
    <Page useStore={useStore}>
      <div className='p-4'>
        <div className="row">
            <div><h1>Ledger</h1></div>
            <div>
              <HelpAlert variant='info' textClass='text-blue' 
                helpLink='/docs/#/invoices'>The ledger is the central record for all invoices types are the primary record of the directing of payment of Bitcoin as SATs into your balance using payment requests or the outflow of payments via vouchers.</HelpAlert>
            </div>
            {isLoading && <div><Spinner animation="border" /></div>}
            {(invoices && satsRate) && 
            <div className='bg-gray w-full flex-row p-1'>

              <div className="m-1 flex flex-row w-full justify-end">
                <button className="btn-common-blue" 
                  onClick={()=>handleCSV()}><BsFillCloudDownloadFill className='mr-1'/> Download CSV</button>

                <button className="btn-common-blue" 
                  onClick={()=>{navigate('/pos/receive')}}><FaCashRegister className='mr-1'/> Receive Payment</button>
                
              </div>

              {invoices.length > 0 && <PaymentsTable deleteHandler={showDeleteConfirmation} satsRate={satsRate} payments={invoices} setPayments={setInvoices} showDelete={true}/>}
              <hr/>
              {invoicesPage && <NewPagination pageInfo={invoicesPage} pageMeta={invoicesPageMeta} handlePageChange={handlePageChange} fetchPage={setInvoicesPage}/>}
            </div> }

        </div>

      </div>

      <ConfirmModal showModal={showModal} actionName={'Delete Invoice'} actionDescription={'delete this invoice'} actionCallback={()=>deleteInvoice()} actionClose={()=>setShowModal(false)} errorValue={deleteModalError}/>
    

    </Page>
  );
};


export default Invoices;
