/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import styled from '@emotion/styled';
import Page from '../../components/Page';

import { WordLogo } from '../../components/PageLayout';

import { Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import wallet_sats from '../../assets/img/wallet_sats.png'
import wallet_blue from '../../assets/img/wallet_blue.png'
import wallet_breeze from '../../assets/img/wallet_breeze.png'
import wallet_phoenix from '../../assets/img/wallet_phoenix.png'

import seen_in_1 from '../../assets/img/ass_seen_in_1.png'
import seen_in_2 from '../../assets/img/ass_seen_in_2.png'
import seen_in_3 from '../../assets/img/ass_seen_in_3.png'
import seen_in_4 from '../../assets/img/ass_seen_in_4.png'
import seen_in_5 from '../../assets/img/ass_seen_in_5.png'


import index_section_1 from '../../assets/img/section-1.png'
import index_section_2 from '../../assets/img/section-2.png'
import index_section_3 from '../../assets/img/section-3.png'
import index_section_4 from '../../assets/img/section-4.png'

import works_with_woo from '../../assets/img/works_with_woo_sm.png'

import { appUrl } from '../../env';

import "./style.css"


const HomeHero = () => {
  let navigate = useNavigate();

  return (<>
        <div className="flex justify-center w-full h-128 
            bg-cover bg-[url('./assets/img/bg-api.png')]" >
            <div className="w-3/4 md:w-1/2 p-3 mt-8 mb-8">
                <div className="flex flex-col justify-center items-center h-full">
                    {/* <img src={icon512} className="w-48 h-48 m-3" /> */}
                    <div className="text-center">
                        <WordLogo className="text-4xl md:text-8xl"/>
                    </div> 
                    <div className="text-6xl mb-2">
                        {/* <img src={imgIcon64} alt="graphene" 
                        className="mr-2 inline-block h-10 align-middle scale-150" /> */}
                    </div>
                    <div className="text-center text-3xl font-bold text-slate-300 m-2">
                        The Simple Cloud Bitcoin Payments API. Rapaygo is the easiest way for application developers to enable Bitcoin payments in the cloud using their own LND instance.
                    </div>
                    <div className="text-center text-3xl font-bold text-slate-300 m-2">
                        {/* <button className="bg-slate-600 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded m-1" onClick={()=>{window.open(`${appUrl}/docs/#/`, '_blank')}}>Get Started</button>
                        <button className="bg-slate-600 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded m-1" onClick={()=>{window.open(`https://documenter.getpostman.com/view/2315651/UyxdKUR7#25029a10-fed6-44b5-99f8-bb662107c9a0`, '_blank')}}>Use Our API</button> */}
                        <button className="bg-slate-600 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded m-1" onClick={()=>navigate('/howitworks')}>Learn More</button>
                    </div>
                </div>
            </div>
      </div>

  </>);
}; 

const Index = ({ i18n, languageCode, changeLanguage }) => {

  let navigate = useNavigate();


  return (
    <>
      <Page withSidenav={false}>

        <HomeHero/>


        {/* <div className='mb-4 p-4'>
           <div className="flex-row text-center w-100 mb-3 md:text-7xl text-5xl"><WordLogo/></div>
           <div className="flex-row text-center text-3xl md:text-5xl">Everything you need to accept Bitcoin payments for your small business in one place.</div>
           <div><p className="flex-row text-center text-2xl md:text-4xl text-gray-600">An inexpensive and simple way to use the lightning network to receive bitcoin payments in minutes.</p></div>
           <div className="flex-row text-center p-2">
             <button className="bg-blue hover:bg-blue-600 text-xl text-white font-bold py-2 px-4 rounded" onClick={()=>{navigate("/signup")}}>Signup Now for Free!</button>
           </div>
        </div> */}

        {/* <div className='p-4 bg-gray-dark text-center'>
          <div className='text-orange text-3xl md:text-5xl'>All payments cost only 1% in processing fees.</div>
        </div> */}
        
        {/* <div className="flex w-full justify-center p-1">

          <div className="flex w-full xl:w-2/3 grid-cols-1 gap-3 lg:grid-cols-2">
            <div className="p-1">
                <div className='p-4 flex'>
                  <div className='flex flex-wrap justify-center'>
                    <div className='p-1 md:w-1/3 lg:w-1/2 w-full'>
                      <img className="mx-auto justify-center rounded-full object-cover md:float-right w-64 h-64" src={index_section_1}/>
                    </div>
                    <div className='p-2 md:w-2/3 lg:w-1/2 text-center md:text-left flex justify-center md:justify-start'>
                        <div className='w-full'>
                          <h2 className="text-orange-600">Point Of Sale Products</h2>
                          <h4>Free Web POS!</h4>
                          <p className='mr-2'>Our frictionless and inexpensive Lightning Network based POS products easily fit on on the countertop and are faster than cards.</p>
                          <button className="bg-orange hover:bg-orange-600 text-white font-bold py-2 px-4 rounded" onClick={()=>{navigate("/howitworks")}}>Learn More</button>
                        </div>
                    </div>
                  </div>
                </div>  
            </div>
            <div className="p-1">
              <div className='p-4 flex w-full'>
                <div className='flex flex-wrap justify-center'>
                  <div className='p-1 md:w-1/3 lg:w-1/2 w-full'>
                    <img className="mx-auto justify-center rounded-full object-cover md:float-right w-64 h-64" src={index_section_2}/>
                  </div>
                  <div className='text-center p-1 md:w-2/3 lg:w-1/2 md:text-left flex justify-center md:justify-start'>

                        <div className='w-full'>
                          <h2 className="text-blue-600">QR Code Based Instant Purchases</h2>
                          <h4>Included with Free Subscription</h4>
                          <p className='mr-2'>Let customers pay instantly using no more than a printed QR code with instant payment.</p>
                          <button className="bg-blue hover:bg-blue-600 text-white font-bold py-2 px-4 rounded" onClick={()=>{navigate("/howitworks")}}>How It Works</button>
                        </div>

                  </div>
                </div>
              </div> 


            </div>
            <div className="p-1">

              <div className='p-4 flex w-full'>
                <div className='flex flex-wrap justify-center'>
                  <div className='p-1 md:w-1/3 lg:w-1/2 w-full'>
                    <img className="mx-auto justify-center rounded-full object-cover md:float-right w-64 h-64" src={index_section_3}/>
                  </div>
                  <div className='p-1 md:w-2/3 lg:w-1/2 flex justify-center md:justify-start'>
                        <div className='text-center w-full'>
                          <h2 className="mt-3 text-green-600">WooCommerce Payment Gateway</h2>
                          <h4>Also Free!</h4>
                          <p>Collect shipping information, track orders and manage basic inventory for your online store all 100% in bitcoin on the Lightning Network.</p>
                          <div className='mb-3 flex justify-center'><img className='sm:mx-auto md:m-1 img-woo-sm' src={works_with_woo}/></div>
                          <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded" onClick={()=>{navigate("/howitworks")}}>Made For Small Business</button>
                        </div>
                  </div>
                </div>
              </div> 


            </div>
            <div className="p-1">

              <div className='p-4 flex w-full'>
                <div className='flex flex-wrap justify-center'>
                  <div className='p-1 md:w-1/3 lg:w-1/2 w-full'>
                    <img className="mx-auto justify-center rounded-full object-cover md:float-right w-64 h-64" src={index_section_4}/>
                  </div>
                  <div className='text-center p-1 md:w-2/3 lg:w-1/2 md:text-left flex justify-center md:justify-start'>
                        <div className='w-full'>
                          <h2 className="mt-3 text-pink-600">No Special Customer Onboarding</h2>
                          <h4>Works With All Lightning Wallets!</h4>
                          <p className='mb-3'>Your customers want use Bitcoin Lightning wallets they already have to buy goods.</p>
                          <button className="bg-pink-500 hover:bg-pink-600 text-white font-bold py-2 px-4 rounded" onClick={()=>{window.open(`${appUrl}/docs/#/invoices?id=what-bitcoin-lightning-wallets-work-with-rapaygo`, '_blank')}}>See Supported Wallets</button>
                        </div>
                  </div>
                </div>
              </div> 


            </div>
          </div>
        </div> */}

        {/* <div className='p-4 flex flex-col mt-4 w-full'>
            <div className='text-center justify-center flex-col w-full'>
              <h4 className='text-5xl'>In The Press</h4></div>
              <div className='flex flex-col md:flex-row justify-center'>
              <div className='w-full flex justify-center'><a href="https://cointelegraph.com/news/us-grassroots-adoption-the-bitcoin-lightning-party-in-portland" target="_new">
                <Image className="scale-75 hover:scale-100" src={seen_in_1} /></a></div>
              
              <div className='w-full flex justify-center'><a href="https://www.mocamboo.com/the-bitcoin-lightning-party-in-portland/" target="_new">
                  <Image className="scale-75 hover:scale-100" src={seen_in_2} /></a></div>

              <div className='w-full flex justify-center'><a href="https://asiatokenfund.com/blockchain/bitcoin-lightning-network-increasing-becoming-the-ideal-choice-for-payment/" target="_new">
                  <Image className="scale-75 hover:scale-100" src={seen_in_3} /></a></div>

              <div className='w-full flex justify-center'><a href="https://www.arover.net/2022/03/31/bitcoin-adoption-in-the-united-states-bitcoin-lightning-festival-in-portland/" target="_new">
                  <Image className="scale-75 hover:scale-100" src={seen_in_4} /></a></div>

              <div className='w-full flex justify-center'><a href="https://www.bullfrag.com/the-bitcoin-lightning-party-in-portland/" target="_new">
                  <Image className="scale-75 hover:scale-100" src={seen_in_5} /></a></div>
              </div> 
        </div>      */}


      </Page>
    </>

  );
}

const WalletsSupported = () => {
  return (
    <ul>
      <li><a href="https://breez.technology/" target="_new"><Image className="img-wallet" src={wallet_breeze} /></a></li>
      <li><a href="https://www.walletofsatoshi.com/" target="_new"><Image className="img-wallet" src={wallet_sats} /></a></li>
      <li><a href="https://phoenix.acinq.co/" target="_new"><Image className="img-wallet" src={wallet_phoenix} /></a></li>
      <li><a href="https://bluewallet.io/" target="_new"><Image className="img-wallet" src={wallet_blue} /></a></li>
    </ul>
  );
}

const SectionContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
`;

const PageContent = styled.div`
margin-bottom: 0px;
`;


export default Index;