import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { withLocale  } from "react-easy-localization";
import { getUser, getAccessTokenInfo, disconnect } from '../service/auth';
import { WordLogo } from '../components/PageLayout';
import { GiHamburgerMenu } from "react-icons/gi";
import { FaUserCircle } from "react-icons/fa";

const Header = () => {
    
    const navigate = useNavigate();

    let flexC = "flex md:flex flex-row md:flex-row md:ml-auto mt-3 md:mt-0 justify-end";
    let hiddenC = "hidden flex md:flex flex-row md:flex-row md:ml-auto mt-3 md:mt-0 justify-end";
    

    const [toggleNav, setToggleNav] = useState(hiddenC);

    const disconnectWallet = event => {
        disconnect();
        window.location.reload();
    };

    const [tokenInfo] = useState(getAccessTokenInfo(getUser()));

    const clickCollapse = event => {
        if(toggleNav.startsWith('hidden')){
            setToggleNav(flexC);
        } else {
            //hidden
            setToggleNav(hiddenC);
        }   
    };

    return (
        <>
            <nav className="color-white py-2 md:py-4 w-full">
                <div className="flex flex-col ">

                    <div className="w-full md:w-fit flex justify-between items-center ">
                        <div className='w-1/3 md:w-full flex'>
                            <div className='flex flex-col ml-2 cursor-pointer' onClick={()=>navigate('/')}>                              
                                <WordLogo className='text-4xl' />
                                <div className='text-sm text-white'>The Simple Cloud Bitcoin Payments API</div>
                            </div>
                        </div>
                        <div className='w-2/3 md:w-0 text-right'>
                            <button className="bg-slate-600 border border-solid  px-3 py-1 h-8 rounded text-white md:hidden" id="navbar-toggle" onClick={()=>clickCollapse()}>
                            <GiHamburgerMenu className='text-white'/>
                        </button></div>
                    </div>

                    <div className='w-full flex justify-end'>
                        <div className={toggleNav} id="navbar-collapse">
                            {/* <button className="p-2 lg:px-4 md:mx-2 text-white rounded hover:underline transition-colors duration-300" onClick={() => navigate('/howitworks', { replace: true })}>How It Works</button> */}

                            {tokenInfo && tokenInfo.active ? 
                            <>  


                                <div className="dropdown inline-block relative">
                                    <button className="hover:text-blue bg-dark text-light font-semibold py-2 px-4 rounded inline-flex items-center">
                                            <FaUserCircle className='mr-1'/><span className="mr-1">{tokenInfo ? `${tokenInfo.payload.sid}` : ""}</span>
                                            <svg className="fill-current h-4 w-4 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/> 
                                            </svg>
                                    </button>
                                    <ul className="dropdown-menu absolute hidden pt-1">
                                        <li className=""><button onClick={()=>navigate('/wallet')} className="hover:underline font-semibold cursor-pointer text-black rounded-t py-2 px-4 block whitespace-no-wrap">Dashboard</button></li>
                                        <li className=""><button onClick={()=>navigate('/settings')} className="hover:underline font-semibold cursor-pointer text-black rounded-t py-2 px-4 block whitespace-no-wrap">Settings</button></li>
                                        <li className=""><button onClick={()=>disconnectWallet()} className="hover:underline font-semibold cursor-pointer text-black rounded-b py-2 px-4 block whitespace-no-wrap">Logout</button></li>
                                    </ul>
                                </div>

                            </>:
                            <>
                                <button className="m-1 p-2 lg:px-4 md:mx-2 text-white rounded bg-cyan-800 hover:bg-cyan-600 transition-colors duration-300" onClick={() => navigate('/pricing', { replace: true })}>Pricing</button>
                                {/* <button className="m-1 p-2 lg:px-4 md:mx-2 text-white rounded bg-green-500 hover:bg-green-600 transition-colors duration-300" onClick={() => navigate('/signup', { replace: true })}>Signup For Free</button> */}
                                <button className="m-1 p-2 lg:px-4 md:mx-2 text-white rounded bg-green-500 hover:bg-green-600 transition-colors duration-300" onClick={() => navigate('/howitworks', { replace: true })}>How It Works</button>

                                {/* <button className="m-1 p-2 lg:px-4 md:mx-2 text-black rounded bg-blue hover:bg-blue-600 transition-colors duration-300" onClick={() => navigate('/login', { replace: true })}>Legacy Login</button> */}
                            </>}  


                        </div>
                    </div>

                </div>
            </nav>

        </>
    );
};


export default Header;