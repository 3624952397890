/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import Page from "../../components/Page";
import { WordLogo } from '../../components/PageLayout';


import "./style.css"
import imgLnd from '../../assets/img/lnd_400x400.png'
import imgVoltage from '../../assets/img/voltage_icon.png'

import imgHiwWallet from '../../assets/img/hiw_wallet.png'
import imgHiwPOS from '../../assets/img/hiw_payment.png'
// import imgHiwInventory from '../../assets/img/hiw_inv.png'

import imgHiwWoo from '../../assets/img/hiw_woo.png'

import imgDots from '../../assets/img/dots.png'




const HowItWorks = () => {

  const navigate = useNavigate();


  return (
    <>
      <Page withSidenav={false}>



        <div className='p-4 bg-slate-50'>
          <div className="flex-row text-center w-100 mb-3 md:text-7xl text-5xl"><WordLogo/></div>
          <h1 className="flex-row text-center">How it works.</h1>

          <div className="flex w-full justify-center p-1">
            <div className="flex w-full grid-cols-1 gap-4 p-2 lg:w-3/4 xl:w-2/3" >
              <div className="p-1">
                <div className='text-center'>
                  <h2>rapaygo is the simple cloud based bitcoin payments Gateway and API for LND.</h2>
                  <p className="index-subtext">
                    rapaygo provides a simple but powerful framework for application developers to manage all the payment gateway transactions for their web applications and to accept bitcoin payments on the Lightning Network as well as On Chain. rapaygo is the easiest way for application developers to enable Bitcoin payments in the cloud.
                  </p>
                </div>
                <div className='w-full flex flex-row justify-center items-center'>
                  <div className='flex flex-col justify-center items-center'>

                    <ul className='text-2xl font-bold w-full md:w-3/4'>
                      <li className="text-left list-disc">
                        You host an LND instance in your cloud, Voltage or your own hardware.
                      </li>
                      <li className="text-left list-disc">
                        We host a managed instance of the rapaygo API.
                      </li>
                      <li className="text-left list-disc">
                        You connect your LND instance to the rapaygo API in minutes.
                      </li>
                    </ul>

                  </div>
                </div>
                <div className='flex flex-col w-full justify-center items-center'>
                  <div className='flex flex-row justify-center'>
                    <img className="m-3 hover:cursor-pointer" onClick={()=>window.open('https://voltage.cloud/', '_blank')} src={imgVoltage} style={{ width: 128 }}/>
                    <img className="m-3 hover:cursor-pointer" onClick={()=>window.open('https://github.com/lightningnetwork/lnd', '_blank')} src={imgLnd} style={{ width: 128 }}/>
                  </div>
                  
                  <p className="flex-row text-center index-subtext text-2xl">
                    With rapaygo you can BYOLND (Bring Your Own Lightning Network Daemon), either by hosting LND yourself or by using the <span onClick={()=>window.open('https://voltage.cloud/', '_blank')} className='link-common hover:text-cyan-600'>voltage cloud</span>. We deploy a managed instance of the rapaygo API for you in our cloud, you connect it to your LND instance and you can start accepting bitcoin payments on the Lightning Network in minutes.
                  </p>
                </div>
                <div className="flex-row text-center w-full">
                  <img className="img-dots mx-auto" src={imgDots} alt="" />
                </div>

              </div>

              {/* <div className="p-1">
                <div className='text-center'>
                    <h2>rapaygo provides simple bitcoin wallet, payment, and e-commerce tools.</h2>
                    <p className="index-subtext">
                          rapaygo provides a simple but powerful framework for merchants to manage all the payment
                          transactions for their business and to accept bitcoin payments on the Lightning Network.
                    </p>
                    <div className='w-full'>
                      <img className="mx-auto" src={imgHiw2} />
                    </div>
                    <div className="flex-row text-center w-full">
                      <img className="img-dots mx-auto" src={imgDots} alt="" />
                  </div>
                </div>

              </div> */}
              {/* <div className="p-1">
                <div className='text-center'>
                    <h2>rapaygo is a managed custodial Lightning Network wallet in the
                        cloud with an easy to understand fee structure.</h2>
                    <div className='w-full'><img className="mx-auto" src={imgHiwWallet} /></div>
                    <p className="index-subtext mt-3">
                        rapaygo gives you a simple lightning network wallet for incoming payment.
                        No need to try to manage your own Lightning Network node, most merchants
                        dont have time to try to understand the details of the Lightning Network
                        and how to create a healthy payment channel, so we do all the hard work for you.
                        We actively monitor the network and ensure that your funding channels are
                        fast and funded. That means quicker processing times and faster payments and
                        all fees always cost only 1% of the transaction amount.
                    </p>
                  </div>
                  <div className="flex-row text-center w-full">
                    <img className="img-dots mx-auto" src={imgDots} alt="" />
                </div>



              </div> */}
              {/* <div className="p-1">
                <div className='text-center'>
                  <h2>rapaygo is simple Point of Sale system for accepting Lightning Network payments.</h2>
                  <div className='w-full'><img className="mx-auto" src={imgHiwPOS} /></div>
                  <p className="index-subtext mt-3">
                    rapaygo has all the core Point of sale tools that merchants are familiar with and expect. 
                    Receive only clerk login, owner overrides for outgoing payments and refunds.
                    Generate purchase invoices for customer payment, QR scanning and payment journey 
                    for the point of sale payment finalization for both the merchants and the customer, 
                    and payments usually finalize in less than 10 seconds. <strong>All the POS tools you need 
                    to accept Lightning Network payments for your business at the counter.</strong>
                  </p>
                  </div>
                  <div className="flex-row text-center w-full">
                    <img className="img-dots mx-auto" src={imgDots} alt="" />
                </div>



              </div> */}
              {/* <div className="p-1">
                <div className='text-center'>

                  <h2>rapaygo is a Payment Gateway for WooCommerce E-commerce Sellers.</h2>
                  <div className='w-full'><img className="mx-auto" src={imgHiwWoo} /></div>
                  <p className="index-subtext">
                    With rapaygo Payment Gateway for <a href="https://woocommerce.com/" target="_blank" rel="noreferrer">WooCommerce</a>, 
                    you can start accepting Bitcoin payments 
                    on the Lightning Network in minutes on your Wordpress based site. 3,876,748 websites use 
                    WooCommerce, or 68,000 of the world's top million websites. That's 0.2% of all 
                    websites, or 6.8% of the top million! WooCommerce is the most popular e-commerce 
                    platform in the world.
                  </p>
                  <div className='flex w-full justify-center'>
                    <button className="text-2xl btn-common-blue" onClick={()=>window.location='https://calendly.com/rapaygo/powering-your-business-with-lightning'}>Attend Our Free Lightning for WooCommerce Seminar!</button>
                  </div>




                </div>
              </div> */}

            </div>
          </div>
    
        </div>
   
      </Page>

    </>
  );
}

export default HowItWorks;