import React, {useEffect, useState} from "react";

import { Row, Col, Button } from "react-bootstrap";
import {useNavigate } from 'react-router-dom';
import Page from "../../components/Page";
import { getUser, getAccessTokenInfo } from '../../service/auth';
import { WalletService } from '../../service/WalletService';

import "./style.css";

const Pricing = () => {

    let navigate = useNavigate();

    const [tokenInfo, setTokenInfo] = useState(getAccessTokenInfo(getUser()));
    const [showAlert, setShowAlert] = useState(false);
    const [wallet, setWallet] = useState();

    useEffect(() => {

        if(tokenInfo.active) {
            WalletService.getWallet().then((wallet) => {
                setWallet(wallet.data); 
            });
        }

    }, [tokenInfo]);

    return (
        <Page withSidenav={false}>
            <div className="pricing-page">
                <div className="pricing-header justify-content-center">
                    <h1>Plans and Pricing</h1>
                    <div>{wallet ? `Your Plan: ${wallet.subscription_type}` : ""}</div>
                </div>
                <div className="pricing-content">
                    <Row className="pricing-content-row">
                        <Col md={1}></Col>
                        <Col md={10} className="pricing-content-item justify-content-center d-flex">
                        <section className="pricing py-5">
                            <div className="container">
                            <div className="row"></div>
                                <div className="row">
                                    {/* free tier (green) */}
                                    <div className="col-lg-4">
                                    <div className="card mb-5 mb-lg-0">
                                        <div className="card-body text-slate-200">
                                        <h5 className="text-4xl card-title text-uppercase text-center pricing-name">Basic</h5>
                                        <h6 className="card-price text-center">$29.95 USD<span className="period">/month</span></h6>
                                        <hr />
                                        <ul className="fa-ul">
                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>                                           
                                                vCPUs: 1	
                                            </li>
                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>                                           
                                                Baseline Performance: 10%	
                                            </li>
                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>                                           
                                                RAM (GiB): 1.0		
                                            </li>
                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>                                           
                                                CPU Credits / Hour: 6	
                                            </li>


                                        </ul>

                                        {/* { tokenInfo.active && wallet && wallet.subscription_type == "FREE" ? <div className="w-100 text-center color-green"><strong>Your Current Plan!</strong></div> : <></>}
                                        { tokenInfo.active ? <></> : <Button variant="orange" className="btn-block btn-primary w-100" onClick={() => {}}>Sign Up Now!</Button>} */}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="card mb-5 mb-lg-0 ">
                                        <div className="card-body text-cyan-400">
                                        <div className="text-bold">
                                            <h5 className="text-4xl card-title text-uppercase text-center pricing-name">Merchant</h5>
                                            <h6 className="card-price text-center">$99.95 USD<span className="period">/month</span></h6>
                                        </div>
                                        <hr />
                                        <ul className="fa-ul">
                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>                                           
                                                vCPUs: 2	
                                            </li>
                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>                                           
                                                Baseline Performance: 40%	
                                            </li>
                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>                                           
                                                RAM (GiB): 4.0		
                                            </li>
                                            <li><span className="fa-li"><i className="fas fa-check"></i></span>                                           
                                                CPU Credits / Hour: 24	
                                            </li>
                                        </ul>
                                        {/* {isLoggedIn() && this.state.showPayButtons ? pricingButtonSapphire : pricingButtonRegisterInactive} */}
                                        { tokenInfo.active && wallet && wallet.subscription_type === "MERCHANT" ? <div className="w-100 text-center color-green"><strong>Your Current Plan!</strong></div> : <></>}
                                        {/* { !tokenInfo.active ? <div className="w-100 text-center"><Button className="w-100" variant="green">Learn More</Button></div> : <></>} */}
                                        { tokenInfo.active && wallet && wallet.subscription_type === "FREE" ? <div className="w-100 text-center"><Button className="w-100" variant="green" onClick={()=>navigate('/upgrade')}>Upgrade Now</Button></div> : <></>}
                                        
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                <div className="card">
                                    <div className="card-body text-green-500">
                                        <div className="text-bold">
                                            <h5 className="text-4xl card-title text-uppercase text-center pricing-name text-bold">Enterprise</h5>
                                            <h6 className="card-price text-center text-bold">Contact Us</h6>
                                        </div>

                                    <hr />
                                    <ul className="fa-ul flex flex-col items-center justify-center">
                                        <li className="text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>Run rapaygo in your own cloud, and we will help you get started.</li>
                                        {/* <li className=" color-dark-green text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>Up To $2500 USD Value Wallet Size</li>
                                        <li className=" color-dark-green text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>Transactions up to 500,000 SATs</li>
                                        <li className=" color-dark-green text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>Order Management and Shipping</li> 
                                        <li className=" color-dark-green text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>E-Commerce Support for Shop Sites</li> 
                                        


                                        <li className=" color-dark-blue text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>rapaygo Vanguard Touchless POS Support</li> 
                                        <li className=" color-dark-blue text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>Product and Inventory Tracking</li> 
                                        <li className=" color-dark-blue text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>rapaygo Vanguard Touchless POS Support</li> 
                                        <li className=" color-dark-blue text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>Print Touchless QR Code Payment Cards</li>                                                                                            
                                        <li className=" color-dark-blue text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>Discord Server Access</li>
                                        <li className=" color-dark-blue text-bold"><span className="fa-li"><i className="fas fa-check"></i></span>Email Support</li>
                                        <li><span className="fa-li"><i className="fas fa-check"></i></span>Web Browser Based Point Of Sale</li>
                                        <li><span className="fa-li"><i className="fas fa-check"></i></span>Product and Inventory Tracking</li>
                                        <li><span className="fa-li"><i className="fas fa-check"></i></span>Documentation Site</li> */}



                                    </ul>
                                    {/* {isLoggedIn() && this.state.showPayButtons ? pricingButtonAmethyst : pricingButtonRegisterInactive} */}
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </section>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                </div>
            </div>    



        </Page>)
};

export default Pricing;